
 /*############################### Global ############################### */

html * {
  font-family: 'Roboto Slab', serif;
}

 /*############################### Elements ###############################*/

button {
    display: inline-flex;
    font-size: large;
    margin-bottom: 10px;
    border-radius: 10px;
}

button:hover {
  cursor: pointer;
}

input, textarea{
    border-radius: 5px;
}

h1 {
  font-size: large;
}


/*############################### Common #######################################*/
.modalclosebtn {
  float: right;
  border: none;
  background: transparent;
}

.formbutton:hover {
  cursor: pointer;
}


 /*############################### Nav component ###############################*/

 .navigation {
  position: relative;
  height: 50px;
}

.navigation button {
  cursor: pointer;
  background: transparent;;
  border: none;
  font-size: 20px;
}

.navigation .hamburgerbutton {
  position: fixed;
  left: 30px;
  top: 20px;
  cursor: pointer;
  background: transparent;;
  border: none;
}

.menu-nav {
  list-style: none;
  position: absolute;
  background: #fff;
  top: 30px;
  left: 0;
  width: 0;
  overflow: hidden;
  max-width: 100px;
  z-index: 9;
  font-size: 18px;
  box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
  transform: translateX(-100px);
  transition: transform ease-in-out 0.2s;
  /* transition: width ease 0.2s; */
}

.menu-nav.show-menu {
  width: 100%;
  transform: translateX(0px);
}

.menu-nav li:hover {
  cursor: pointer;
}

.menu-nav li a, .menu-nav li span {
  display: block;
}

/*############################### Hud component ###############################*/

.huddiv {
  height: 100%;
  width: 100%;
}

.hud-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 5fr 1fr;
  grid-template-areas: 
    "header-left header-right"
    "heading speedometer"
    "refresh refresh"
  ;
  justify-items: center;
  align-items: center;
}

.hud-grid-header-left {
  grid-area: header-left;
}
.hud-grid-header-right {
  grid-area: header-right;
}

.hud-grid-heading {
  grid-area: heading;
}

.hud-grid-speedometer {
  grid-area: speedometer;
}

.hud-grid-refresh {
  grid-area: refresh;
}

.hud-map {
  width: 100%;
  /* height: 45vh; */
}

/*############################### Heading component ###############################*/

 .headingdiv {
    display: inline-flex;
}

/*############################### Speeometer component ###############################*/

 .speedometerdiv {
    display: inline-flex;
}


/*############################### Map component ###############################*/

 .leaflet-container {
    width: 90%;
    height: 50vh;
    grid-area: map;
}


/*############################### Trips component ###############################*/

.tripsdiv {
  font-size: large;
}

.tripsdiv td {
  margin: 10px;
  padding: 10px;
  text-align: center;
  
}

.tripsdiv th {
  margin: 10px;
  padding: 10px;
}

.tripsdiv tr:hover {
  cursor: pointer;
}


/*############################### Newtrip component ###############################*/
 
 .newtrip-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "header header"
    "startdatetimelabel startposnamelabel"
    "startdatetime startposname"
    "passengerslabel noteslabel"
    "passengers notes"
    "formbutton formbutton"
    ;
    justify-items: center;
    align-items: center;
    row-gap: 5px;
    font-size: large;
  }

  .newtrip-header-grid {
    grid-area: header;
  }

  label[for="idstartdatetime"] {
    grid-area: startdatetimelabel;
  }
  label[for="idstartposname"] {
    grid-area: startposnamelabel;
  }
  #idstartdatetime {
    grid-area: startdatetime;
  }
  #idstartposname {
    grid-area: startposname;
  }
  label[for="idpassengers"] {
    grid-area: passengerslabel
  }
  label[for="notes"] {
    grid-area: noteslabel;
  }
  #idpassengers {
    grid-area: passengers;
  }
  #idnotes {
    grid-area: notes;
  }

  /*############################### Edittrip component ###############################*/

.edittripdiv input:hover {
  cursor: pointer;
}

.edittripdiv li {
  font-size: large;
}

.edittrip-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
  "header header"
  "startdatetimelabel startposnamelabel"
  "startdatetime startposname"
  "enddatetimelabel endposnamelabel"
  "enddatetime endposname"
  "passengerslabel noteslabel"
  "passengers notes"
  "tripacculabel overnightstayslabel"
  "tripaccu overnightstays"
  "enginehourslabel tripstatuslabel"
  "enginehours tripactive"
  "none trippassive"
  "deletebutton formbutton"
  ;
  justify-items: center;
  align-items: center;
  row-gap: 5px;
  font-size: large;
}

.gridheader {
  grid-area: header;
}
label[for="idstartdatetime"] {
  grid-area: startdatetimelabel;
}
label[for="idstartposname"] {
  grid-area: startposnamelabel;
}
#idstartdatetime {
  grid-area: startdatetime;
}
#idstartposname {
  grid-area: startposname;
}
label[for="idenddatetime"] {
  grid-area: enddatetimelabel;
}
label[for="idendposname"] {
  grid-area: endposnamelabel;
}
#idenddatetime {
  grid-area: enddatetime;
}
#idendposname {
  grid-area: endposname;
}
label[for="idpassengers"] {
  grid-area: passengerslabel
}
label[for="idnotes"] {
  grid-area: noteslabel;
}
#idpassengers {
  grid-area: passengers;
}
#idnotes {
  grid-area: notes;
}
label[for="idtripaccu"] {
  grid-area: tripacculabel;
}
label[for="idovernightstays"] {
  grid-area: overnightstayslabel;
}
#idtripaccu {
  grid-area: tripaccu
}
#idovernightstays {
  grid-area: overnightstays;
}
label[for="idenginehours"] {
  grid-area: enginehourslabel;
}
#idtripstatuslabel {
  grid-area: tripstatuslabel;
}
#idenginehours { 
  grid-area: enginehours;
}
label[for="idtripactive"] {
  grid-area: tripactive;
}
label[for="idtrippassive"] {
  grid-area: trippassive;
}
#iddeletebutton {
  grid-area: deletebutton;
}
.formbutton {
  grid-area: formbutton;
  border-radius: 10px;
  font-size: large;
}
/*############################### Fuel component ###############################*/

.fueldiv {
  font-size: large;
}

.fueldiv td {
  margin: 10px;
  padding: 10px;
  text-align: center;
  
}

.fueldiv th {
  margin: 10px;
  padding: 10px;
}

.fueldiv tr:hover {
  cursor: pointer;
}


/*############################### Newfueling component ###############################*/
 
 .newfueling-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "header header"
    "fueldatetimelabel fuelposnamelabel"
    "fueldatetime fuelposname"
    "fuellabel fuellabel"
    "fuel fuel"
    "fuelenghourslabel fueltriplabel"
    "fuelenghours fueltrip"
    "formbutton formbutton"
    ;
    justify-items: center;
    align-items: center;
    row-gap: 5px;
    font-size: large;
  }

  .newfueling-header-grid {
    grid-area: header;
  }

  label[for="idfueldatetime"] {
    grid-area: fueldatetimelabel;
  }
  label[for="idfuelposname"] {
    grid-area: fuelposnamelabel;
  }
  #idfueldatetime {
    grid-area: fueldatetime;
  }
  #idfuelposname {
    grid-area: fuelposname;
  }
  label[for="idfuel"] {
    grid-area: fuellabel
  }
  #idfuel {
    grid-area: fuel;
  }
  label[for="idfuelenghours"] {
    grid-area: fuelenghourslabel;
  }
  #idfuelenghours {
    grid-area: fuelenghours;
  }
  label[for="idfueltrip"] {
    grid-area: fueltriplabel;
  }
  #idfueltrip {
    grid-area: fueltrip;
  }

  /*############################### Editfueling component ###############################*/

.editfuelingdiv input:hover {
  cursor: pointer;
}

.editfuelingdiv li {
  font-size: large;
}

.editfueling-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
  "header header"
  "fueldatetimelabel fuelposnamelabel"
  "fueldatetime fuelposname"
  "fuellabel fuellabel"
  "fuel fuel"
  "fuelenghourslabel fueltriplabel"
  "fuelenghours fueltrip"
  "submit submit"
  "deletebutton formbutton"
  ;
  justify-items: center;
  align-items: center;
  row-gap: 5px;
  font-size: large;
}


.editfueling-header-grid {
  grid-area: header;
}

label[for="idfueldatetime"] {
  grid-area: fueldatetimelabel;
}
label[for="idfuelposname"] {
  grid-area: fuelposnamelabel;
}
#idfueldatetime {
  grid-area: fueldatetime;
}
#idfuelposname {
  grid-area: fuelposname;
}
label[for="idfuel"] {
  grid-area: fuellabel
}
#idfuel {
  grid-area: fuel;
}
label[for="idfuelenghours"] {
  grid-area: fuelenghourslabel;
}
#idfuelenghours {
  grid-area: fuelenghours;
}
label[for="idfueltrip"] {
  grid-area: fueltriplabel;
}
#idfueltrip {
  grid-area: fueltrip;
}
#iddeletebutton {
  grid-area: deletebutton;
}
.formbutton {
  grid-area: formbutton;
  border-radius: 10px;
  font-size: large;
}


/*############################### Modal ###############################*/

.modal {
    position: absolute;
    top: 5%;
    left: 5%;
    right: 5%;
    padding: 2%;
    border-radius: 20px;
    background-color: lightgrey;
}

.modaloverlay {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ReactModalPortal {
  position: fixed;
  z-index: 2000;
}

.ReactModal__Overlay {
  overflow-y: auto;
}


/*############################### leaflet ###############################*/

.leaflet-container {
  margin: auto;
}